import { render, staticRenderFns } from "./CurrentCollaboration.vue?vue&type=template&id=eb00faf4&scoped=true"
import script from "./CurrentCollaboration.vue?vue&type=script&lang=js"
export * from "./CurrentCollaboration.vue?vue&type=script&lang=js"
import style0 from "./CurrentCollaboration.vue?vue&type=style&index=0&id=eb00faf4&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb00faf4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oliver/Documents/jiashi/jiashi1.0/node_modules/.store/vue-hot-reload-api@2.3.4/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('eb00faf4')) {
      api.createRecord('eb00faf4', component.options)
    } else {
      api.reload('eb00faf4', component.options)
    }
    module.hot.accept("./CurrentCollaboration.vue?vue&type=template&id=eb00faf4&scoped=true", function () {
      api.rerender('eb00faf4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CurrentCollaboration.vue"
export default component.exports