var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-agorartc-box" },
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.remoteUid,
            expression: "remoteUid",
          },
        ],
        ref: "agorartc",
        staticClass: "agorartc",
        attrs: { id: "agorartc-wrap" },
      }),
      !_vm.remoteUid
        ? _c("el-empty", {
            staticClass: "agorartc-empty",
            attrs: { description: "暂无会话" },
          })
        : _vm._e(),
      _vm.currentUrl
        ? _c("div", { staticClass: "shot-box" }, [
            _c("img", {
              staticClass: "img",
              attrs: { src: _vm.currentUrl, alt: "" },
            }),
            _c(
              "div",
              { staticClass: "btns" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { size: "medium" },
                    on: { click: _vm.cancelShot },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "btn",
                    attrs: { type: "primary", size: "medium" },
                    on: { click: _vm.confirmShot },
                  },
                  [_vm._v("确 定")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.whiteRoomFlag
        ? _c("div", { staticClass: "white-room" }, [
            _c(
              "div",
              {
                ref: "imgBox",
                staticClass: "img-box",
                class: _vm.maxWidth ? "maxWidth" : "maxHeight",
              },
              [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.whiteRoomUrl, alt: "" },
                }),
                _c("div", { ref: "whiteRoom", staticClass: "room" }),
                _c(
                  "div",
                  { staticClass: "toolbar", attrs: { id: "toolbar" } },
                  _vm._l(_vm.toolNames, function (item) {
                    return _c(
                      "button",
                      {
                        key: item.value,
                        staticClass: "toolbar-item",
                        on: {
                          click: function ($event) {
                            return _vm.setToolBar(item)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "close-board",
                    attrs: { size: "small", type: "success", round: "" },
                    on: { click: _vm.exitWhiteRoom },
                  },
                  [_vm._v("关闭白板")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.trackFlag
        ? _c(
            "div",
            {
              staticClass: "track-box",
              style: {
                width: _vm.trackStyle.width + "px",
                height: _vm.trackStyle.height + "px",
              },
              on: {
                mousedown: _vm.handleMouseDown,
                mousemove: _vm.handleMouseMove,
                mouseup: _vm.handleMouseUp,
              },
            },
            [
              _c("div", {
                staticClass: "draw",
                style: {
                  width: _vm.drawStyle.width + "px",
                  height: _vm.drawStyle.height + "px",
                  marginLeft: _vm.drawStyle.marginLeft + "px",
                  marginTop: _vm.drawStyle.marginTop + "px",
                  borderWidth: _vm.drawStyle.borderWidth + "px",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "close-track",
                  attrs: { size: "small", type: "success", round: "" },
                  on: { click: _vm.exitTrack },
                },
                [_vm._v("关闭追踪")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }