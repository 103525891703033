<template>
  <el-dialog class="js-dialog-form js-dialog-call" :visible.sync="dialogVisible" :close-on-click-modal="false" :show-close="false" width="360px">
    <ul class="exit">
      <li>
        <i class="icon" @click.stop="handleLeave">离开</i>
      </li>
      <li>
        <i class="icon red" @click.stop="handleOver">结束</i>
      </li>
    </ul>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  props: ['value'],
  data() {
    return {}
  },
  computed: {
    dialogVisible: {
      get(){
        return this.value
      },
      set(newVal){
        this.$emit('input', newVal)
      }
    }
  },
  mounted() {},
  methods: {
    handleLeave(){
      this.$emit('handleLeave')
    },
    handleOver(){
      this.$emit('handleOver')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/initiateCollaboration.scss';
.js-dialog-call{
  .el-dialog{
    background: url("../assets/images/18.png") no-repeat center;
    background-size: 100%;
  }
  .icon-leave{
    background: url("../assets/images/32.png") no-repeat center;
    background-size: 100%;
  }
  .exit{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  .icon {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #2C5FFF;
    line-height: 78px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    
    &.red {
      background: #E40652;
    }
  }
}
</style>