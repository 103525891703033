<template>
  <div class="create-agorartc-box">
    <!-- 视频容器 -->
    <div
      v-show="remoteUid"
      ref="agorartc"
      class="agorartc"
      id="agorartc-wrap"
    ></div>
    <el-empty
      class="agorartc-empty"
      v-if="!remoteUid"
      description="暂无会话"
    ></el-empty>
    <!-- 截屏标记-->
    <div v-if="currentUrl" class="shot-box">
      <img class="img" :src="currentUrl" alt="" />
      <div class="btns">
        <el-button class="btn" size="medium" @click="cancelShot"
          >取 消</el-button
        >
        <el-button class="btn" type="primary" size="medium" @click="confirmShot"
          >确 定</el-button
        >
      </div>
    </div>
    <!-- 冻屏标记 -->
    <div v-if="whiteRoomFlag" class="white-room">
      <div
        ref="imgBox"
        class="img-box"
        :class="maxWidth ? 'maxWidth' : 'maxHeight'"
      >
        <img class="img" :src="whiteRoomUrl" alt="" />
        <div ref="whiteRoom" class="room"></div>
        <div id="toolbar" class="toolbar">
          <button
            class="toolbar-item"
            v-for="item in toolNames"
            :key="item.value"
            @click="setToolBar(item)"
          >
            {{ item.name }}
          </button>
        </div>
        <el-button
          class="close-board"
          size="small"
          type="success"
          round
          @click="exitWhiteRoom"
          >关闭白板</el-button
        >
      </div>
    </div>
    <!-- 追踪标记 -->
    <div
      v-if="trackFlag"
      class="track-box"
      @mousedown="handleMouseDown"
      @mousemove="handleMouseMove"
      @mouseup="handleMouseUp"
      :style="{
        width: trackStyle.width + 'px',
        height: trackStyle.height + 'px',
      }"
    >
      <div
        class="draw"
        :style="{
          width: drawStyle.width + 'px',
          height: drawStyle.height + 'px',
          marginLeft: drawStyle.marginLeft + 'px',
          marginTop: drawStyle.marginTop + 'px',
          borderWidth: drawStyle.borderWidth + 'px',
        }"
      ></div>
      <el-button
        class="close-track"
        size="small"
        type="success"
        round
        @click="exitTrack"
        >关闭追踪</el-button
      >
    </div>
  </div>
</template>

<script>
import AgoraRTC from "agora-rtc-sdk-ng";
import { mapGetters, mapMutations } from "vuex";
import { WhiteWebSdk } from "white-web-sdk";
export default {
  name: "",
  data() {
    return {
      currentUrl: "",
      uid: "",
      remoteUid: "1",
      rtcClient: "",
      rtcToken: "",
      rtcOperation: {
        type: "opera",
        operaContent: {
          uid: "",
          rtcChannel: "",
          operaName: "",
          operaValue: "",
          followObj: {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            trackShotUrl: "", //跟踪标记截图
          },
        },
      },
      localTracks: {
        audioTrack: null,
        screenVideoTrack: null,
        screenAudioTrack: null,
      },
      remoteTracks: {
        videoTrack: null,
        audioTrack: null,
      },
      screenTrack: null,
      remoteUsers: {},
      whiteWebSdk: "",
      whiteWebSdkOptions: "",
      whiteRoomUrl: "",
      whiteRoomFlag: false,
      shotType: "",
      whiteRoom: "",
      toolNames: [
        { name: "曲线", value: "pencil" },
        { name: "方形", value: "rectangle" },
        { name: "文本", value: "text" },
        { name: "箭头", value: "arrow" },
        { name: "椭圆", value: "ellipse" },
        { name: "直线", value: "straight" },
        { name: "橡皮", value: "eraser" },
      ],
      maxWidth: true,
      trackFlag: false,
      trackStyle: {
        width: 0,
        height: 0,
      },
      drawStyle: {
        flag: false,
        width: 0,
        height: 0,
        marginLeft: 0,
        marginTop: 0,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        borderWidth: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["getUserInfo", "getRtcOptions", "getSessionArr",'getRongCloud']),
  },
  created() {
    this.$eventBus.$off("callAnswer");
    this.$eventBus.$on("callAnswer", () => {
      this.$nextTick(() => {
        this.init();
      });
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$eventBus.$off("callAnswer");
    // this.exitWhiteRoom()
  },
  methods: {
    ...mapMutations(["setSessionArr"]),
    init() {
      this.rtcOperation.operaContent.rtcChannel = this.getRongCloud.channel;
      this.uid = parseInt(localStorage.getItem("userId"));
      this.rtcOperation.operaContent.uid = this.uid;
      this.createRongCloudRTC();
    },
    // 创建白板Room并获取白板RoomToken
    getRoomToken() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: "api/csp/rtc/v1/agora/netlessRoomToken",
        })
          .then((response) => {
            let data = response.data;
            resolve(data.data);
          })
          .catch(() => {
            reject();
          });
      });
    },
    // 初始化白板实例
    async joinWhiteRoom() {
      this.whiteWebSdkOptions = await this.getRoomToken();
      this.whiteWebSdk = new WhiteWebSdk({
        appIdentifier: this.whiteWebSdkOptions.appId,
        region: "cn-hz",
      });
    },
    //创建白板
    async createWhite() {
      this.whiteRoomFlag = true;
      // 初始化白板
      await this.joinWhiteRoom();
      let joinRoomParams = {
        uuid: this.whiteWebSdkOptions.channel,
        uid: this.whiteWebSdkOptions.userId + "",
        roomToken: this.whiteWebSdkOptions.token,
      };
      await this.whiteWebSdk.joinRoom(joinRoomParams).then((room) => {
        this.whiteRoom = room;
        this.setVideoAspectRatio();
        this.whiteRoom.setViewMode("broadcaster");
        this.whiteRoom.bindHtmlElement(this.$refs.whiteRoom);

        let W = this.$refs.whiteRoom.clientWidth;
        let H = this.$refs.whiteRoom.clientHeight;
        let screenCenterX = W / 2;
        let screenCenterY = H / 2;
        let pointInWorld = room.convertToPointInWorld({
          x: screenCenterX,
          y: screenCenterY,
        });
        let imageInformation = {
          uuid: this.whiteWebSdkOptions.channel,
          centerX: pointInWorld.x,
          centerY: pointInWorld.y,
          width: W,
          height: H,
          locked: false,
        };

        this.whiteRoom.insertImage(imageInformation);
        this.whiteRoom.completeImageUpload(
          this.whiteWebSdkOptions.channel,
          this.whiteRoomUrl
        );
        this.whiteRoom.lockImage(this.whiteWebSdkOptions.channel, true);
        let options = {
          appId: this.whiteWebSdkOptions.appId,
          channel: this.whiteWebSdkOptions.channel,
          roomToken: this.whiteWebSdkOptions.token,
        };
        this.$parent.sendWhiteBoard(options, this.whiteRoomUrl);
      });
    },
    // 初始化教具
    setToolBar(item) {
      this.whiteRoom.setMemberState({
        currentApplianceName: item.value,
        shapeType: "pentagram",
        strokeColor: [255, 0, 0],
        strokeWidth: 2,
        textSize: 24,
      });
    },
    // 白板退出
    async exitWhiteRoom() {
      let msg = {
        type: "chat",
        chatContent: {
          userRealName: this.getUserInfo.realName,
          oneself: 1,
          type: 23,
          channel: this.whiteWebSdkOptions.channel,
          roomToken: this.whiteWebSdkOptions.token,
          content: "白板退出",
        },
      };
      this.$parent.sendMsg(msg);
      await this.whiteRoom.disconnect();
      this.whiteRoomFlag = false;
      this.whiteRoomUrl = "";
    },
    // 获取RTC token
    getRtcToken() {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: `api/csp/rtc/v1/agora/rtcToken/${this.rtcOperation.operaContent.rtcChannel}`,
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              this.rtcToken = data.data;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    // 创建RTC client
    async createRongCloudRTC() {
      // channel是否存在
      if (!this.rtcOperation.operaContent.rtcChannel) {
        return;
      }
      await this.getRtcToken();
      this.rtcClient = AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });
      let options = {
        appid: localStorage.getItem("appId"),
        channel: this.getRtcOptions.channel,
        token: this.rtcToken,
        uid: this.uid,
      };
      this.joinAgoraRTC(options);
    },
    // 加入RTC channel
    async joinAgoraRTC(options) {
      this.rtcClient.on("user-published", this.handleUserPublished);
      this.rtcClient.on("user-unpublished", this.handleUserUnpublished);
      console.log(
        "---------------------------------------------------rtcoptions---------------------------------------------------"
      );
      // 加入频道
      await this.rtcClient.join(
        options.appid,
        options.channel,
        options.token,
        options.uid
      );
      // 发送给后台标记这个channel已经有人加入了
      await this.$axios({
        method: "post",
        url: `api/csp/rc/v1/session/join`,
        data: {
          data: {
            channel: options.channel,
            type: "1",
          },
        },
      });
      this.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await this.rtcClient.publish(this.localTracks.audioTrack);
      // 更新本地会话列表
      let tmp = {
        channel: options.channel,
        token: options.token,
      };
      let sessionArr = this.getSessionArr;
      let flag = sessionArr.some((item) => {
        return item.channel == tmp.channel;
      });
      if (!flag) {
        sessionArr.push(tmp);
      }
      this.$store.commit("setSessionArr", sessionArr);
    },
    handleUserPublished(user, mediaType) {
      const id = user.uid;
      this.remoteUsers[id] = user;
      this.subscribe(user, mediaType);
    },
    handleUserUnpublished(user, mediaType) {
      if (mediaType === "video") {
        const id = user.uid;
        delete this.remoteUsers[id];
      }
    },
    async subscribe(user, mediaType) {
      await this.rtcClient.subscribe(user, mediaType); // 声网API，订阅远端用户的音视频轨道
      console.log("媒体类型", user, mediaType);
      let uid = sessionStorage.getItem("chooseUid");
      if (uid != null) {
        // if(user.uid != uid || uid === undefined) {
        console.log("媒体类型 1111", uid);
        // return
        // }
      } else {
        // 已经显示就返回
        if (this.remoteTracks.videoTrack) {
          console.log("媒体类型 2222", this.remoteTracks);
          return;
        }
      }

      this.remoteUid = user.uid;
      this.$nextTick(() => {
        if (mediaType === "video") {
          this.remoteTracks.videoTrack = user.videoTrack;
          user.videoTrack.play("agorartc-wrap", { fit: "contain" });
        }

        if (mediaType === "audio") {
          this.remoteTracks.audioTrack = user.audioTrack;
          this.remoteTracks.audioTrack.play();
        }
        this.$parent.getUserByChannel();
      });
    },
    async handleOperation(o) {
      // 静麦
      if (o.operaName === "mike") {
        if (this.localTracks.audioTrack) {
          this.localTracks.audioTrack.setVolume(Number(o.value));
        } else {
          console.log(`本地的音频轨道不存在：${this.localTracks.audioTrack}`);
        }
      }
      // 变焦
      if (o.operaName === "change-focal") {
        this.rtcOperation.operaContent.operaName = o.operaName;
        this.rtcOperation.operaContent.operaValue = o.value;
        this.$parent.sendMsg(this.rtcOperation);
      }
      // 对焦
      if (o.operaName === "focal") {
        this.rtcOperation.operaContent.operaName = o.operaName;
        this.rtcOperation.operaContent.operaValue = o.value;
        this.$parent.sendMsg(this.rtcOperation);
      }
      // 音量
      if (o.operaName === "volume") {
        if (this.remoteTracks.audioTrack) {
          this.remoteTracks.audioTrack.setVolume(Number(o.value));
        } else {
          console.log(`远端的音频轨道不存在：${this.localTracks.audioTrack}`);
        }
      }
      // 清除标记
      if (o === "clear-mark") {
      }
      // 全屏
      if (o.operaName === "all-screen") {
        this.launchFullScreen();
      }
      // 共享屏幕
      if (o.operaName === "share-screen") {
        this.localTracks.screenVideoTrack =
          await AgoraRTC.createScreenVideoTrack(
            {
              encoderConfig: {
                framerate: 15,
                height: 720,
                width: 1280,
              },
            },
            "disable"
          );
        await this.rtcClient.publish(this.localTracks.screenVideoTrack);
        this.rtcOperation.operaContent.operaName = o.operaName;
        this.rtcOperation.operaContent.operaValue = 1;
        this.$parent.sendMsg(this.rtcOperation);

        this.localTracks.screenVideoTrack.on("track-ended", () => {
          this.unpublishScreen();
          this.localTracks.screenVideoTrack &&
            this.localTracks.screenVideoTrack.stop() &&
            this.localTracks.screenVideoTrack.close();
          this.localTracks.screenVideoTrack = null;

          this.rtcOperation.operaContent.operaValue = 0;
          this.$parent.sendMsg(this.rtcOperation);
        });
      }
    },
    async unpublishScreen() {
      await this.rtcClient.unpublish(this.localTracks.screenVideoTrack);
    },
    // 离开，结束弹窗
    leaveOrExit() {
      if (!this.rtcClient) {
        return;
      }
      this.$parent.showLeaveOrExit();
    },
    // 离开
    leaveSession() {
      this.handleLeave();
      // this.exit()
    },
    // 结束
    overSession() {
      // 先发送退出消息
      this.$parent.sendMsg({
        type: "leave",
      });
      // 然后离开
      this.handleLeave();
    },
    handleLeave() {
      // 加入的会话组中删除该会话
      let sessionArr = this.getSessionArr;
      let newSessionArr = sessionArr.filter((item) => {
        return item.channel != this.getRtcOptions.channel;
      });
      this.$store.commit("setSessionArr", newSessionArr);
      this.exit();
    },
    async clientLeave() {
      if (!this.rtcClient) {
        return;
      }
      for (let trackName in this.localTracks) {
        let track = this.localTracks[trackName];
        if (track) {
          track.stop();
          track.close();
          this.localTracks[trackName] = undefined;
        }
      }
      this.remoteUid = "";
      this.remoteUsers = {};
      await this.rtcClient.leave();
    },
    exit() {
      this.clientLeave();
      // 当前会话置为空
      this.$store.commit("setRtcOptions", {
        channel: "",
        token: "",
      });
    },
    // 全屏
    launchFullScreen() {
      let element = document.getElementById("agorartc-wrap");
      if (element) {
        if (element.requestFullScreen) {
          element.requestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      }
    },
    // 截图
    shotImg(type) {
      if (!this.remoteTracks.videoTrack) return;
      let imgData = this.remoteTracks.videoTrack.getCurrentFrameData();
      if (type == 1) {
        this.whiteRoomUrl = this.$Utils.imageDataToImage(imgData);
        this.createWhite();
      }
      if (type == 3) {
        this.currentUrl = this.$Utils.imageDataToImage(imgData);
      }
    },
    // 保存截图
    saveShot() {
      return new Promise((resolve, reject) => {
        let imgData = this.remoteTracks.videoTrack.getCurrentFrameData();
        const base64 = this.$Utils.imageDataToImage(imgData);

        //  this.rtcOperation.operaContent.followObj.trackShotUrl
        this.$axios({
          url: "api/csp/cos/v1/url/uploadBase64",
          method: "post",
          data: {
            data: {
              base64,
              suffix: "jpeg",
            },
          },
        }).then(({ data }) => {
          if (!data.status) {
            resolve(data.data);
          } else {
            reject();
          }
        });
      });
    },
    cancelShot() {
      this.currentUrl = "";
    },
    confirmShot() {
      this.$parent.sendShotImg(this.currentUrl);
      this.cancelShot();
    },
    getVideoAspectRatio() {
      let agoW = this.$refs.agorartc.clientWidth;
      let agoH = this.$refs.agorartc.clientHeight;
      let num = agoW * 720 - agoH * 1280;
      return num;
    },
    // 设置视频宽高比
    setVideoAspectRatio() {
      let num = this.getVideoAspectRatio();
      if (num > 0) {
        this.maxWidth = true;
      } else {
        this.maxWidth = false;
      }
    },
    followSign() {
      if (!this.remoteTracks.videoTrack) return;
      // 初始化追踪面板宽高
      this.setTrackAspectRatio();
      this.trackFlag = true;
    },
    // 初始化追踪面板宽高
    setTrackAspectRatio() {
      let num = this.getVideoAspectRatio();
      if (num > 0) {
        this.trackStyle.width = (this.$refs.agorartc.clientHeight * 1280) / 720;
        this.trackStyle.height = this.$refs.agorartc.clientHeight;
      } else {
        this.trackStyle.width = this.$refs.agorartc.clientWidth;
        this.trackStyle.height = (this.$refs.agorartc.clientWidth * 720) / 1280;
      }
    },
    // 追踪标记
    handleMouseDown($event) {
      this.drawStyle.flag = true;
      this.drawStyle.width = 0;
      this.drawStyle.height = 0;
      this.drawStyle.borderWidth = 0;
      this.drawStyle.startX = $event.clientX;
      this.drawStyle.startY = $event.clientY;
      this.drawStyle.marginLeft = $event.offsetX;
      this.drawStyle.marginTop = $event.offsetY;
    },
    handleMouseMove($event) {
      if (this.drawStyle.flag) {
        this.drawStyle.endX = $event.clientX;
        this.drawStyle.endY = $event.clientY;
        this.drawStyle.borderWidth = 2;

        let w = this.drawStyle.endX - this.drawStyle.startX;
        let h = this.drawStyle.endY - this.drawStyle.startY;
        if (w > 0 && h > 0) {
          this.drawStyle.width = w;
          this.drawStyle.height = h;
        }
      }
    },
    async handleMouseUp() {
      let ratio = 1280 / this.trackStyle.width;
      this.drawStyle.flag = false;
      let options = {
        x: this.drawStyle.marginLeft,
        y: this.drawStyle.marginTop,
        w: this.drawStyle.width,
        h: this.drawStyle.height,
      };
      if (options.w < 20 || options.h < 20) return;
      this.rtcOperation.operaContent.operaName = "follow";
      // 点坐标
      let points = {
        x1: (options.x * ratio) / 1280,
        y1: (options.y * ratio) / 720,
        x2: ((options.x + options.w) * ratio) / 1280,
        y2: ((options.y + options.h) * ratio) / 720,
      };
      this.rtcOperation.operaContent.followObj = points;
      // 取异步地址发送给安卓
      this.rtcOperation.operaContent.followObj.trackShotUrl =
        await this.saveShot();
      console.log(this.rtcOperation);
      this.$parent.sendMsg(this.rtcOperation);
    },
    clearTrack() {
      this.drawStyle = {
        flag: false,
        width: 0,
        height: 0,
        marginLeft: 0,
        marginTop: 0,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        borderWidth: 0,
      };
    },
    exitTrack() {
      this.trackFlag = false;
      this.clearTrack();
      this.rtcOperation.operaContent.operaName = "follow";
      this.rtcOperation.operaContent.operaValue = -1;
      this.$parent.sendMsg(this.rtcOperation);

      // 关闭追踪后，重置参数
      this.rtcOperation.operaContent.operaValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variable.scss";
.create-agorartc-box {
  position: relative;
  width: 100%;
  height: 100%;
}
.agorartc {
  width: 100%;
  height: 100%;
  &-empty {
    height: 100%;
  }
}
.shot-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  .img {
    width: 960px;
    height: 540px;
  }
  .btns {
    margin-top: 40px;
    .btn {
      margin: 0 20px;
    }
  }
}
.white-room {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: center;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  .img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
    &-box {
      position: relative;
      display: inline-block;
      &.maxWidth {
        max-width: 100%;
        height: 100%;
      }
      &.maxHeight {
        width: 100%;
        max-height: 100%;
      }
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #000;
      }
      .close-board {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 5;
      }
    }
  }
  .room {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 4;
  }
  .toolbar {
    position: absolute;
    left: 10px;
    top: 50%;
    width: 44px;
    z-index: 10;
    transform: translateY(-50%);
    &-item {
      font-size: 12px;
      padding: 4px 10px;
      &:hover {
        cursor: pointer;
        background: $jsTheme;
        color: #fff;
      }
    }
  }
}
.track-box {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  .draw {
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    border: 2px solid #f00;
  }
  .close-track {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 5;
  }
}
</style>
