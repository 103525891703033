<template>
  <div class="js-cur-wrap">
    <!-- 分屏 -->
    <div v-show="getIsSplitScreen" class="js-common-wrap js-cur-collaboration">
      <div class="media-box" :class="mediaClass">
        <div class="media-box-item" v-for="i in mediaCount" :key="i">
          <div class="split-item-opera" @dblclick="switchCurrentScreen(i - 1)">
            <!-- <span class="user-name">张三</span> -->
            <ul class="btns">
              <!-- <li class="btns-item"><i class="mike"></i>静麦</li> -->
              <li class="btns-item">
                <i class="exit" @click="kickOutUser(i - 1)"></i>踢出
              </li>
              <!-- <li class="btns-item"><i class="volume"></i>静音</li> -->
            </ul>
          </div>
          <div class="tip">暂无会话</div>
        </div>
      </div>
      <div class="media-opera">
        <ul>
          <li>
            <el-button round size="small" @click="switchScreen(1)"
              >返回单屏</el-button
            >
          </li>
          <li>
            <el-button
              :class="mediaCount === 4 ? '' : 'is-plain'"
              round
              type="primary"
              size="small"
              @click="switchScreen(4)"
              >4分屏</el-button
            >
          </li>
          <li>
            <el-button
              :class="mediaCount === 9 ? '' : 'is-plain'"
              round
              type="primary"
              size="small"
              @click="switchScreen(9)"
              >9分屏</el-button
            >
          </li>
          <li>
            <el-button
              :class="mediaCount === 16 ? '' : 'is-plain'"
              round
              type="primary"
              size="small"
              @click="switchScreen(16)"
              >16分屏</el-button
            >
          </li>
        </ul>
        <!-- <button @click="clearApplication">返回</button> -->
      </div>
    </div>

    <!-- 单视频 -->
    <div v-show="!getIsSplitScreen" class="js-common-wrap js-cur-collaboration">
      <!-- 视频主区 -->
      <div class="video">
        <div class="agoraRTC">
          <!-- video area -->
          <div id="rong-cloud-tv" ref="agorartc">
            <div class="video-wrapper"></div>
            <el-empty
              v-if="noSignal"
              style="height: 100%"
              description="暂无会话"
            ></el-empty>
            <!-- 截屏标记-->
            <div v-if="currentUrl" class="shot-box">
              <img class="img" :src="currentUrl" alt="" />
              <div class="btns">
                <el-button class="btn" size="medium" @click="cancelShot"
                  >取 消</el-button
                >
                <el-button
                  class="btn"
                  type="primary"
                  size="medium"
                  @click="confirmShot"
                  >确 定</el-button
                >
              </div>
            </div>
            <!-- 追踪标记 -->
            <div
              v-if="trackFlag"
              class="track-box"
              @mousedown="handleMouseDown"
              @mousemove="handleMouseMove"
              @mouseup="handleMouseUp"
              :style="{
                width: trackStyle.width + 'px',
                height: trackStyle.height + 'px',
              }"
            >
              <div
                class="draw"
                v-if="drawStyle.flag"
                :style="{
                  width: drawStyle.width + 'px',
                  height: drawStyle.height + 'px',
                  marginLeft: drawStyle.marginLeft + 'px',
                  marginTop: drawStyle.marginTop + 'px',
                  borderWidth: drawStyle.borderWidth + 'px',
                }"
              ></div>
              <el-button
                class="close-track"
                size="small"
                type="success"
                round
                @click="exitTrack"
                >关闭追踪</el-button
              >
            </div>
          </div>
          <div class="map-box" v-if="showMap">
            <baidu-map
              :initMap="initMap"
              :scrollWheelZoom="true"
              :showCall="false"
              :polylinesArr="polylinesArr"
              :center="center"
            ></baidu-map>
          </div>
        </div>
        <!-- 视频操作功能区 -->
        <ul class="agoraRTC-opera">
          <li>
            <i
              class="icon icon-mode"
              :class="operaList.mode ? 'active' : ''"
              @click="splitScreen"
            ></i>
            <p>分屏模式</p>
          </li>
          <li>
            <i
              class="icon icon-change-focal"
              :class="operaList.changeFocal.value == 1 ? '' : 'active'"
              @click="handleChaneFocal"
            ></i>
            <p>{{ operaList.changeFocal.text }}</p>
          </li>
          <!-- 对焦 -->
          <li>
            <el-popover
              placement="top-start"
              trigger="manual"
              v-model="focalPopver"
            >
              <div class="agoraRTC-focal-list">
                <p
                  v-for="i in focalList"
                  :key="i.value"
                  class="item"
                  :class="i.value == focalValue ? 'active' : ''"
                  @click="handleFocal(i.value)"
                >
                  {{ i.text }}
                </p>
              </div>
              <i
                slot="reference"
                class="icon icon-focal"
                :class="operaList.focal ? 'active' : ''"
                @click="focalPopver = !focalPopver"
              ></i>
            </el-popover>
            <p>对焦</p>
          </li>
          <!-- 标记 -->
          <li>
            <el-popover
              placement="top-start"
              width="200"
              trigger="manual"
              v-model="markPopver"
            >
              <div class="agoraRTC-mark-list">
                <p
                  class="item"
                  v-for="(item, index) in markList"
                  :class="[
                    item.class,
                    currentMark == item.value ? 'active' : '',
                  ]"
                  :key="index"
                  @click="markItemClick(item)"
                >
                  <i></i>{{ item.text }}
                </p>
              </div>
              <i
                slot="reference"
                class="icon icon-mark"
                :class="operaList.mark ? 'active' : ''"
                @click="handleMark"
              ></i>
            </el-popover>
            <p>标记</p>
          </li>
          <!-- 暂时隐藏 -->
          <!-- <li>
            <i class="icon icon-clear-mark" :class="operaList.clearMark?'active':''" @click="handleClearMark"></i>
            <p>清除标记</p>
          </li> -->
          <li>
            <el-popover
              placement="top-start"
              width="200"
              trigger="manual"
              v-model="sessionPersonPopver"
            >
              <div class="agoraRTC-user-list">
                <div
                  class="item"
                  v-for="(person, index) in sessionPerson"
                  :key="index"
                >
                  <i></i>
                  <div class="info">
                    <p>{{ person.name }}</p>
                    <p>{{ person.realName }}</p>
                  </div>
                </div>
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  round
                  @click="joinSession"
                  >邀请<i class="el-icon-plus"></i
                ></el-button>
              </div>
              <i
                slot="reference"
                class="icon icon-num"
                :class="operaList.setNum ? 'active' : ''"
                @click="handleSetNum"
                ><span class="num">{{ sessionNum }}</span></i
              >
            </el-popover>
            <p>参会人</p>
          </li>
          <li>
            <i class="icon icon-map" @click="handleMap"></i>
            <p>地图</p>
          </li>
          <li>
            <i
              class="icon icon-screen"
              :class="operaList.allScreen ? 'active' : ''"
              @click="handleAllScreen"
            ></i>
            <p>全屏</p>
          </li>
          <li>
            <i
              class="icon icon-share-screen"
              :class="operaList.shareScreen ? 'active' : ''"
              @click="handleShareScreen"
            ></i>
            <p>共享屏幕</p>
          </li>
          <li>
            <el-popover placement="top" trigger="hover" content="">
              <div class="popup">
                <el-button plain @click="leaveRoom">离开</el-button>
                <el-button type="danger" plain @click="endConversation"
                  >结束对话</el-button
                >
              </div>
              <div class="wrapper" slot="reference">
                <i class="icon icon-exit"></i>
                <p>退出会话</p>
              </div>
            </el-popover>
          </li>
        </ul>
      </div>
      <!-- 右侧消息区 -->
      <div class="silder-list">
        <div class="silder-list-title">{{ silderList.title }}</div>
        <div class="silder-list-content">
          <div v-if="silderList.showIm" class="chat">
            <!-- 聊天信息 -->
            <div class="chat-info">
              <div
                class="chat-info-details"
                :class="
                  getChatRoomMessages && getChatRoomMessages.length
                    ? ''
                    : 'no-data'
                "
              >
                <ul v-if="getChatRoomMessages && getChatRoomMessages.length">
                  <li v-for="(info, index) in getChatRoomMessages" :key="index">
                    <template v-if="info.type > 30 && info.type < 40">
                      <div>
                        <p class="name" :class="info.oneself ? 'self' : ''">
                          {{ info.userRealName }}
                        </p>
                        <p
                          class="info"
                          v-if="info.type == 31"
                          :class="info.oneself ? 'self' : ''"
                        >
                          <span class="text">{{ info.content }}</span>
                        </p>
                        <p
                          class="info"
                          v-if="info.type == 32"
                          :class="info.oneself ? 'self' : ''"
                        >
                          <el-image
                            class="img"
                            :src="info.content"
                            :preview-src-list="[info.content]"
                          ></el-image>
                        </p>
                        <p
                          class="info"
                          v-if="info.type == 33"
                          :class="info.oneself ? 'self' : ''"
                        >
                          <span class="href">
                            <a :href="info.content" target="_blank"
                              >点击下载文件</a
                            >
                          </span>
                        </p>
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        <p v-if="info.type == 21" class="sysInfo">
                          {{ info.userRealName }}创建白板
                        </p>
                        <p v-if="info.type == 22" class="sysInfo">
                          {{ info.userRealName }}加入白板
                        </p>
                        <p v-if="info.type == 23" class="sysInfo">
                          {{ info.userRealName }}退出白板
                        </p>
                      </div>
                    </template>
                  </li>
                </ul>
                <el-empty v-else description="暂无数据"></el-empty>
              </div>
            </div>
            <!-- 聊天输入 -->
            <div class="chat-input">
              <el-upload
                class="upload"
                action="#"
                accept=".png,.jpg"
                :show-file-list="false"
                :auto-upload="false"
                :on-change="handleHttpRequest"
              >
                <i class="el-icon-folder-opened"></i>
              </el-upload>
              <el-input
                class="input"
                v-model="currentChat"
                size="small"
                placeholder="请输入……"
                clearable
                @keyup.enter.native="handleKeyUpEnter"
              ></el-input>
            </div>
          </div>
          <ul v-else class="session-person-content js-call-list">
            <li
              class="js-call-list-item"
              v-for="item in userList"
              :key="item.userId"
            >
              <img
                class="client-icon"
                src="@/assets/images/userImg.png"
                alt=""
              />
              <div class="client-info">
                <p class="client-name">{{ item.realName }}</p>
                <i
                  class="client-type"
                  :class="item.devType === 'web' ? 'client-type-web' : ''"
                ></i>
              </div>
              <el-button
                class="client-call"
                type="primary"
                size="mini"
                plain
                @click="handleInvite(item)"
                >邀请</el-button
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 导入 RTCLib、CallLib
import {
  installer as rtcInstaller,
  RCRTCClient,
  RCTrack,
  RCFrameRate,
  RCResolution,
  RCLocalTrack,
} from "@rongcloud/plugin-rtc";
import * as RongIMLib from "@rongcloud/imlib-next";
import BaiduMap from "@/components/BaiduMap";
import CreateRongCloudRtc from "@/components/CreateRongCloudRTC";
import { mapGetters } from "vuex";
import ExitBox from "@/components/Exit";
import ScreenShot from "js-web-screen-shot";

export default {
  name: "CurrentCollaboration",
  components: {
    BaiduMap,
    CreateRongCloudRtc,
    ExitBox,
  },
  data() {
    return {
      currentUrl: "",
      // 地图相关参数
      polylinesArr: [],
      center: { lng: 116.404, lat: 39.915 },
      showMap: false,
      operaOptions: {
        value: "",
        operaName: "",
      },
      // 视频操作区参数
      operaList: {
        mode: false,
        mike: {
          value: 1,
          text: "静麦",
        },
        changeFocal: {
          value: 1,
          text: "变焦",
        },
        focal: false,
        volume: false,
        mark: false,
        clearMark: false,
        setNum: false,
        allScreen: false,
        shareScreen: false,
      },
      volume: 50,
      focalValue: 1,
      focalList: [
        {
          value: 1,
          text: "近",
        },
        {
          value: 2,
          text: "中",
        },
        {
          value: 3,
          text: "远",
        },
      ],
      // 频道号
      rtcChannel: "",
      initMap: false,
      sessionPerson: [],
      silderList: {
        title: "消息",
        showIm: true,
      },
      userList: [],
      sessionNum: 0,
      focalPopver: false,
      markPopver: false,
      sessionPersonPopver: false,
      // 聊天窗口对话
      currentChat: "",
      // 发送文件类型
      fileType: "",
      action: "",
      currentMsg: {
        type: "chat",
        chatContent: {
          userRealName: "",
          oneself: 1,
          type: "",
          content: "",
          appId: "",
          channel: "",
          roomToken: "",
        },
      },
      markList: [
        {
          value: 3,
          text: "截屏标记",
          class: "item-shot",
        },
        // 融云没有白板，做不了
        {
          value: 1,
          text: "冻屏标记",
          class: "item-frozen",
        },
        {
          value: 2,
          text: "追踪标记",
          class: "item-track",
        },
      ],
      // 选中的标记类型
      currentMark: 0,
      // 会话集合
      sessionClients: [],
      // 会话集合中对应的channel
      sessionClientsChannels: [],
      // 远程用户信息
      remoteUsers: {},
      // 当前的房间
      currentRoom: null,
      // 本地的音频流
      audioTrack: null,
      // 本地的视频流
      videoTrack: null,
      // 操作参数
      rtcOperation: {
        type: "opera",
        operaContent: {
          uid: "",
          rtcChannel: "",
          operaName: "",
          operaValue: "",
          followObj: {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            trackShotUrl: "", //跟踪标记截图
          },
        },
      },
      // 跟踪标记DOM
      trackFlag: false,
      // 跟踪标记参数
      drawStyle: {
        flag: false,
        width: 0,
        height: 0,
        marginLeft: 0,
        marginTop: 0,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        borderWidth: 0,
      },
      CommonRtcClient: null,
      noSignal: true,
      trackStyle: {
        width: 0,
        height: 0,
      },
      mediaCount: 4,
      mediaClass: "",
      // 多屏初始化的索引
      initiaVideosGroupNum: 0,
      // 双击切换回单屏的用户id
      doubleClickUser: null,
      // 存储的流信息
      stream: [],
    };
  },
  computed: {
    ...mapGetters([
      "getRongCloud",
      "getUserInfo",
      "getRtcOptions",
      "getSessionArr",
      "getCallDialogData",
      "getChatRoomMessages",
      "getIsSplitScreen",
    ]),
  },
  mounted() {
    this.initial();
  },
  beforeDestroy() {
    if (this.rtcChannel) {
      this.leaveRoom();
    }
  },
  watch: {
    volume(val, oldVal) {
      if (val !== oldVal) {
        this.operaOptions.value = val;
      }
    },
  },
  methods: {
    resetInvite() {
      this.operaList.setNum = false;
      this.sessionPersonPopver = false;
    },
    // 踢人
    kickOutUser(index) {
      const message = { type: "KickOut" };
      const targetId = this.stream[index]._userId || 0;

      // remove ele
      const wraper = document.querySelectorAll(".media-box-item")[index];
      wraper.removeChild(wraper.querySelector("video"));
      wraper.querySelector(".hide").setAttribute("class", "tip");
      // show tips
      this.sendMessage(message, targetId, false);
    },
    // 开启分屏
    splitScreen() {
      this.exitTrack();

      this.$store.commit("setIsSplitScreen", true);
      this.mediaCount = 4;
      this.mediaClass = `split-screen${this.mediaCount}`;
    },
    // 切换分屏数量
    switchScreen(count) {
      if (count === 1) {
        this.$store.commit("setIsSplitScreen", false);
        return;
      }
      if (this.initiaVideosGroupNum > count) {
        this.$message.error("当前视频数大于目标分屏数量！请切换更多路分屏！");
        return false;
      }

      this.mediaCount = count;
      this.mediaClass = `split-screen${this.mediaCount}`;
    },
    // 返回单屏
    async switchCurrentScreen(index) {
      this.$store.commit("setIsSplitScreen", false);
      this.doubleClickUser = this.stream[index]._userId;
      // 切屏的时候一定要重置这个初始化数字，以它来判断切到哪一个；
      this.initiaVideosGroupNum = 0;
      const { code } = await this.CommonRtcClient.leaveRoom(this.currentRoom);
      console.log(`leave room code: ${code}`);
      await this.initial();
    },
    cancelShot() {
      this.currentUrl = "";
    },
    confirmShot() {
      this.sendShotImg(this.currentUrl);
      this.cancelShot();
    },
    clearTrack() {
      this.drawStyle = {
        flag: false,
        width: 0,
        height: 0,
        marginLeft: 0,
        marginTop: 0,
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        borderWidth: 0,
      };
    },
    async exitTrack() {
      const { channelId } = this.getRongCloud;

      this.trackFlag = false;
      this.clearTrack();
      this.rtcOperation.operaContent.operaName = "follow";
      this.rtcOperation.operaContent.operaValue = -1;

      await this.sendMessage(this.rtcOperation, channelId, true);

      // 关闭追踪后，重置参数
      this.rtcOperation.operaContent.operaValue = "";
    },
    // video当前帧转图像
    currentFrameToImg() {
      let img = null;
      let canvas = document.createElement("canvas");
      let video = document.querySelector("#rong-cloud-tv [signal=rc-video]");
      // 截图的时候需要给canvas宽高，不然就出现截图不全的问题了
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      let w = video.videoWidth;
      let h = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0, w, h);
      img = canvas.toDataURL("image/png");

      return img;
    },

    initial() {
      // 全局RTC对象初始化完成，并且connect之后state为ready，再进行房间初始化
      if (window?.GLOBAL?.state === "ready") {
        this.CommonRtcClient = window.GLOBAL.CommonRtcClient;
        this.CommonRongIMLib = window.GLOBAL.CommonRongIMLib;
        this.CommonRongCaller = window.GLOBAL.Caller;
        this.Events = window.GLOBAL.Events;

        // 有电话标记的时候，初始化房间
        if (this.$route.query?.origin === "call") {
          // 复制
          this.rtcChannel = this.getRongCloud.channelId;
          this.currentMsg.chatContent.userRealName = this.getUserInfo.realName;

          this.initialRoom(this.rtcChannel);
        }
      } else {
        setTimeout(this.initial, 2000);
      }
    },
    // 初始化聊天室
    async initialRoom(roomId) {
      // 加入普通音视频房间，从 5.0.7 开始增加返回 `tracks` 与 `userIds`
      // * userIds - 当前已加入房间的远端人员列表
      // * tracks  - 当前已发布至房间内的远端资源列表
      const _this = this;
      const {
        code: joinCode,
        room,
        userIds,
        tracks: remoteTracks,
      } = await this.CommonRtcClient.joinRTCRoom(roomId);
      this.currentRoom = room;
      console.log(joinCode, room, userIds, remoteTracks, roomId);
      // service记录一遍已经加入过的channel
      await this.$axios({
        method: "post",
        url: `api/csp/rc/v1/session/join`,
        data: {
          data: {
            channel: roomId,
            type: "1",
          },
        },
      });

      // 若加入失败，则 room、userIds、tracks 值为 undefined
      if (joinCode !== 10000) {
        console.log("join living room failed:", code);
        return;
      }

      // 注册房间事件监听器，重复注册时，仅最后一次注册有效
      room.registerRoomEventListener({
        /**
         * 本端被踢出房间时触发
         * @description 被踢出房间可能是由于服务端超出一定时间未能收到 rtcPing 消息，所以认为己方离线。
         * 另一种可能是己方 rtcPing 失败次数超出上限，故而主动断线
         * @param byServer
         * 当值为 false 时，说明本端 rtcPing 超时
         * 当值为 true 时，说明本端收到被踢出房间通知
         */
        onKickOff(byServer) {
          // 当本地已获取资源后，需要调用 track.destroy() 销毁已获取的资源， track 为 RCMicphoneAudioTrack 或 RCCameraVideoTrack 类型实例
        },
        /**
         * 接收到房间信令时回调，用户可通过房间实例的 `sendMessage(name, content)` 接口发送信令
         * @param name 信令名
         * @param content 信令内容
         * @param senderUserId 发送者 Id
         * @param messageUId 消息唯一标识
         */
        onMessageReceive(name, content, senderUserId, messageUId) {},
        /**
         * 监听房间属性变更通知
         * @param name
         * @param content
         */
        onRoomAttributeChange(name, content) {},
        /**
         * 发布者禁用/启用音频
         * @param audioTrack RCRemoteAudioTrack 类实例
         */
        onAudioMuteChange(audioTrack) {},
        /**
         * 发布者禁用/启用视频
         * @param videoTrack RCRemoteVideoTrack 类实例对象
         */
        onVideoMuteChange(videoTrack) {},
        /**
         * 房间内其他用户新发布资源时触发
         * 如需获取加入房间之前房间内某个用户发布的资源列表，可使用 room.getRemoteTracksByUserId('userId') 获取
         * @param tracks 新发布的音轨与视轨数据列表，包含新发布的 RCRemoteAudioTrack 与 RCRemoteVideoTrack 实例
         */
        async onTrackPublish(tracks) {
          // 按业务需求选择需要订阅资源，通过 room.subscribe 接口进行订阅
          const { code: subscribeCode } = await room.subscribe(tracks);
          if (subscribeCode !== 10000) {
            console.log("资源订阅失败 ->", subscribeCode);
          }
        },
        /**
         * 房间用户取消发布资源
         * @param tracks 被取消发布的音轨与视轨数据列表
         * @description 当资源被取消发布时，SDK 内部会取消对相关资源的订阅，业务层仅需处理 UI 业务
         */
        onTrackUnpublish(tracks) {},
        /**
         * 订阅的音视频流通道已建立, track 已可以进行播放
         * @param track RCRemoteTrack 类实例
         */
        onTrackReady(track) {
          console.log(track);

          if (track.isAudioTrack()) {
            // 音轨不需要传递播放控件
            setTimeout(() => {
              track.play();
            }, 0);
          } else if (track.isVideoTrack() && track._tag === "1") {
            /*   视频源播放控制:
             *      case1:单屏播放   store.isSplitScreen/getIsSplitScreen => false
             *      case2:分屏播放(include case4 and initial split screen)    store.isSplitScreen/getIsSplitScreen === true
             *      case3:分屏=>单屏  data.currentSelectedIndex!==null
             *      case4:单屏=>分屏
             */
            const index = _this.initiaVideosGroupNum;
            const wrapper = document.querySelector(
              "#rong-cloud-tv .video-wrapper"
            );
            const element = document.createElement("video");
            const wrapper2 =
              document.querySelectorAll(".media-box-item")[index];
            // 解决本地通话video自动播放的bug
            element.muted = true;
            element.autoplay = true;
            // 解决全屏播放显示不全的问题
            element.style.maxWidth = "100%";
            element.style.maxHeight = "100%";
            // 设置class，用于后面的自动播放
            element.setAttribute("class", "rc-video-" + track.getTrackId());
            // 设置统一标识，用于跟踪算法获取参数
            element.setAttribute("signal", "rc-video");

            const copyEle = element.cloneNode();

            // 双击切换回单屏,video渲染控制
            wrapper.innerHTML = "";

            if (
              !_this.getIsSplitScreen &&
              _this.doubleClickUser !== null &&
              _this.doubleClickUser === track._userId
            ) {
              wrapper.appendChild(element);
            }
            // 初始化渲染：未在多屏模式下双击切单屏
            if (_this.doubleClickUser === null) {
              wrapper.appendChild(element);
              wrapper2.appendChild(copyEle);

              //分屏播放隐藏tips提示
              wrapper2.querySelector(".tip").setAttribute("class", "hide");
              // 播放一个
              // const node = document.getElementById(
              //   "rc-video-" + track.getTrackId()
              // );
            }

            // 多个video自动播放
            const node = document.querySelectorAll(
              ".rc-video-" + track.getTrackId()
            );
            [...node].map((video) => {
              track.play(video);
            });

            // 记录至外部参数
            _this.videoTrack = track;
            // 单屏没信号
            _this.noSignal = false;
            // 记录流，方便外部获取数据
            _this.stream.push(track);
            // 关闭邀请人弹窗
            _this.resetInvite();
            // 累加，用于匹配切换的核心参数
            _this.initiaVideosGroupNum += 1;
            /**  自动分屏逻辑:condition:只有在非双击切屏的时候触发
             * 当目前视频流超过2，为4分屏
             * 当目前视频流超过4，小于9，为9分屏
             * 当目前视频流超过9，小于16，为16分屏
             **/
            if (_this.doubleClickUser === null) {
              _this.autoSplitScreen();
            }
          }
        },
        /**
         * 人员加入
         * @param userIds 加入的人员 id 列表
         */
        onUserJoin(userIds) {},
        /**
         * 人员退出
         * @param userIds
         */
        onUserLeave(userIds) {},
      });

      const { code } = await room.subscribe(remoteTracks);

      if (code !== 10000) {
        console.log(`资源订阅失败 -> code: ${code}`);
      } else {
        console.log(`资源订阅成功~~~~~~~~~~~~~~~~~`);
      }
      // 发布本地音频
      this.publishLocalAudio();
      // 加入聊天室IM
      this.joinChatRoom(roomId);
    },
    autoSplitScreen() {
      if (this.initiaVideosGroupNum > 1) {
        this.$store.commit("setIsSplitScreen", true);
      } else {
        this.$store.commit("setIsSplitScreen", false);
      }
      if (this.initiaVideosGroupNum < 4) {
        this.mediaCount = 4;
        this.mediaClass = `split-screen4`;
      } else if (this.initiaVideosGroupNum < 9) {
        this.mediaCount = 9;
        this.mediaClass = `split-screen9`;
      } else if (this.initiaVideosGroupNum < 16) {
        this.mediaCount = 16;
        this.mediaClass = `split-screen16`;
      }
    },
    registerRoom(room) {},
    joinChatRoom(roomId) {
      const count = -1;

      this.CommonRongIMLib.joinChatRoom(roomId, {
        count,
      })
        .then((res) => {
          // 加入聊天室成功
          if (res.code === this.CommonRongIMLib.ErrorCode.SUCCESS) {
            console.log("聊天室加入成功：", res.code);
          } else {
            console.log("聊天室加入失败：", res.code, res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.chatRoomObserver();
    },
    chatRoomObserver() {
      this.CommonRongIMLib.addEventListener(
        RongIMLib.Events.MESSAGES,
        (event) => {
          // 处理消息队列
          event.messages.map((message) => {
            console.log(message);
            // 文本消息
            if (message.messageType === "RC:TxtMsg") {
              const content = JSON.parse(message.content.content);

              Object.keys(content).map((key) => {
                // ------------------------------聊天室消息处理-----------------------
                if (key === "type") {
                  if (content.type == "leave") {
                    if (this.getRongCloud.channelId) {
                      this.leaveRoom();
                    }
                  } else if (
                    content.type === "join" ||
                    content.type === "out"
                  ) {
                    // 用户进入或退出房间，刷新参会人和通讯列表
                    this.getCallList();
                    this.getUserByChannel();
                  }
                }
                // 远程用户接受之后；刷新邀请人列表
                else if (key === "rtc_accept" && content[key].from) {
                  // 用户进入或退出房间，刷新参会人和通讯列表
                  this.getCallList();
                  this.getUserByChannel();
                }
              });
            }
            console.log(event.messages);
          });
        }
      );
    },
    async publishLocalAudio() {
      // 推送本地音频
      const { code: localAudioCode, track: audioTrack } =
        await this.CommonRtcClient.createMicrophoneAudioTrack();
      if (localAudioCode !== 10000) {
        console.error("本地创建音频流失败" + localAudioCode);
      }
      this.audioTrack = audioTrack;

      const { code } = await this.currentRoom.publish([audioTrack]);

      // 若资源发布失败
      if (code !== 10000) {
        console.log("资源发布失败:", code);
      }
    },
    async unpublish() {
      const { code } = await this.currentRoom.unpublish([
        this.audioTrack,
        this.videoTrack,
      ]);
      console.log(code);
    },
    async leave() {
      // 离开RTC房间
      const { code } = await this.CommonRtcClient.leaveRoom(this.currentRoom);
      console.log(`leave room code: ${code}`);
      // 取消订阅
      this.unpublish();
      // 离开IM 聊天室
      this.CommonRongIMLib.quitChatRoom(this.currentRoom).then((res) => {
        // 退出聊天室成功
        if (res.code === 0) {
          console.log("退出聊天室 " + chatRoomId);
        }
      });
      // 清空聊天室信息
      this.$store.commit("setChatRoomMessages", []);
      // 移除video标签，不然提示符跑偏

      document.querySelector("#rong-cloud-tv [signal=rc-video]").remove();
      this.noSignal = true;
    },

    // 全屏
    handleAllScreen() {
      let element = document.querySelector("#rong-cloud-tv [signal=rc-video]");
      if (element) {
        if (element.requestFullScreen) {
          element.requestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        }
      }
    },
    // 共享屏幕
    async handleShareScreen() {
      const { code, track: screenTrack } =
        await this.CommonRtcClient.createScreenVideoTrack();
      this.videoTrack = screenTrack;
      if (code !== 10000) {
        console.warn("捕获屏幕共享流失败 ->", code);
        return;
      }
      // 发送信息
      const { channelId } = this.getRongCloud;
      this.rtcOperation.operaContent.operaName = "share-screen";
      this.rtcOperation.operaContent.operaValue = 1;
      this.sendMessage(this.rtcOperation, channelId, true);

      //
      const { code: publishCode } = await this.currentRoom.publish([
        screenTrack,
      ]);

      // 若资源发布失败
      if (publishCode !== 10000) {
        console.log("资源发布失败:", code);
      }
      // 监听屏幕共享流结束事件, RCLocalTrack 可以通过 import { RCLocalTrack } from '@rongcloud/plugin-rtc' 获取
      screenTrack.on(RCLocalTrack.EVENT_LOCAL_TRACK_END, (track) => {
        // 取消发布屏幕共享流，room 为当前加入的房间实例
        this.currentRoom.unpublish([track]);

        // 取消视频分享
        this.rtcOperation.operaContent.operaValue = 0;

        this.sendMessage(this.rtcOperation, channelId, true);
      });

      // this.localTracks.screenVideoTrack.on("track-ended", () => {
      //   debugger;
      //   this.unpublishScreen();
      //   this.localTracks.screenVideoTrack &&
      //     this.localTracks.screenVideoTrack.stop() &&
      //     this.localTracks.screenVideoTrack.close();
      //   this.localTracks.screenVideoTrack = null;

      //   this.rtcOperation.operaContent.operaValue = 0;
      //   this.$parent.sendMsg(this.rtcOperation);
      // });
    },
    // Rong Cloud IM message
    sendMessage(msg, targetId, isChatRoom) {
      return new Promise((resolve, reject) => {
        const { CHATROOM, PRIVATE } = this.CommonRongIMLib.ConversationType;
        // 指定消息发送的目标会话
        const conversation = {
          // targetId
          targetId,
          // 会话类型：RongIMLib.ConversationType.PRIVATE | RongIMLib.ConversationType.GROUP|CHATROOM
          conversationType: isChatRoom ? CHATROOM : PRIVATE,
        };

        // 构建文本消息
        const message = new this.CommonRongIMLib.TextMessage({
          content: JSON.stringify(msg),
        });
        const options = {
          // 如果需要发送 @ 消息，isMentioned 需设置为 true
          isMentioned: true,
          // 消息发送前的回调，可以使用此回调返回的 message 用于列表渲染
          onSendBefore: (message) => {
            console.log("消息发送前的回调", message);
          },
        };
        console.log("发送信息:", conversation, message);
        // 发送消息
        this.CommonRongIMLib.sendMessage(conversation, message, options)
          .then(({ code, data }) => {
            if (code === 0) {
              resolve(resolve);
              console.log("消息发送成功：", data);
              this.resetChat();
            } else {
              reject("消息发送失败：", code);
              console.log("消息发送失败：", code);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetChat() {
      this.currentChat = "";
      this.currentMsg = {
        type: "chat",
        chatContent: {
          userRealName: "",
          oneself: 1,
          type: "",
          content: "",
          appId: "",
          channel: "",
          roomToken: "",
        },
      };
    },
    joinSession() {
      this.getCallList();
    },
    // 根据channel获取通讯列表
    getCallList() {
      if (!this.getRongCloud.channelId) {
        return;
      }
      this.$axios({
        method: "get",
        url: `api/csp/rc/v1/session/noAttendeeUsers/${this.rtcChannel}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.userList = data.data;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 清除本地存储，临时调试用，调试完成删除
    clearApplication() {
      let tmp = {
        channel: "",
        token: "",
      };
      this.$store.commit("setRtcOptions", tmp);
      this.$store.commit("setSessionArr", []);
    },
    // 退出
    async leaveRoom() {
      if (this.rtcChannel) {
        await this.leave();
        this.$message("退出频道成功！");
      }
    },
    // 结束会话
    endConversation() {
      if (this.rtcChannel) {
        const { channelId } = this.getRongCloud;
        const message = { type: "leave" };

        this.sendMessage(message, channelId, true);
        this.leave();
        this.$message("结束会话成功！");
      }
    },
    handleOperation(o) {
      // 变焦
      if (o.operaName === "change-focal") {
        this.rtcOperation.operaContent.operaName = o.operaName;
        this.rtcOperation.operaContent.operaValue = o.value;
      }
      // 对焦
      if (o.operaName === "focal") {
        this.rtcOperation.operaContent.operaName = o.operaName;
        this.rtcOperation.operaContent.operaValue = o.value;
      }
      const { channelId } = this.getRongCloud;

      this.sendMessage(this.rtcOperation, channelId, true);
    },
    // 变焦
    handleChaneFocal() {
      if (this.operaList.changeFocal.value === 1) {
        this.operaList.changeFocal.value = 2;
      } else {
        this.operaList.changeFocal.value = 1;
      }
      this.operaOptions.operaName = "change-focal";
      this.operaOptions.value = this.operaList.changeFocal.value;
      this.handleOperation(this.operaOptions);
    },
    // 对焦
    handleFocal(value) {
      this.focalValue = value;
      if (value === 1) {
        this.operaList.focal = false;
      } else {
        this.operaList.focal = true;
      }
      this.operaOptions.operaName = "focal";
      this.operaOptions.value = value;
      this.handleOperation(this.operaOptions);
    },
    // 标记
    handleMark() {
      if (this.markPopver) {
        this.markPopver = false;
        this.operaList.mark = false;
      } else {
        this.markPopver = true;
        this.operaList.mark = true;
      }
    },
    // 标记
    markItemClick(item) {
      this.markPopver = false;
      this.currentMark = item.value;

      // 冻屏
      if (item.value === 1) {
        new ScreenShot({
          enableWebRtc: false,
          completeCallback: (base64) => {
            let imgUrl = "";
            try {
              if (
                Object.prototype.toString.call(base64) === "[object String]"
              ) {
                imgUrl = base64;
              } else if (1) {
                imgUrl = base64.base64;
              }
            } catch (error) {
              console.log(error);
            }
            // test model
            // var img = document.createElement("img");
            // img.src = imgUrl;
            // document.body.appendChild(img);

            // 保存发送截图
            this.sendShotImg(imgUrl);
            this.cancelShot();
          },
          closeCallback: () => {
            console.log("截图窗口关闭");
          },
          hiddenToolIco: {
            save: true,
            separateLine: true,
            //
          },
          imgSrc: this.currentFrameToImg(),
          // cropBoxInfo: {
          //   x: 0,
          //   y: 0,
          //   w: window.offsetWidth,
          //   h: window.offsetHeight,
          // },
          level: 99999999,
          // screenShotDom: document.querySelector(".video-wrapper [signal=rc-video]"),
        });
      }
      // 追踪
      else if (item.value === 2) {
        this.followMark();
      }
      // 截屏
      else if (item.value === 3) {
        this.shotScreen();
      }
    },
    shotScreen() {
      this.currentUrl = this.currentFrameToImg();
    },
    // 跟踪标记
    followMark() {
      if (!this.videoTrack) return;
      // 初始化追踪面板宽高
      this.setTrackAspectRatio();
      this.trackFlag = true;
    },
    // 初始化追踪面板宽高
    setTrackAspectRatio() {
      let num = this.getVideoAspectRatio();
      const ele = document.querySelector("#rong-cloud-tv [signal=rc-video]");
      let width = ele.offsetWidth;
      let height = ele.offsetHeight;

      if (num > 0) {
        this.trackStyle.width = (height * 1280) / 720;
        this.trackStyle.height = height;
      } else {
        this.trackStyle.width = width;
        this.trackStyle.height = (width * 720) / 1280;
      }
    },
    getVideoAspectRatio() {
      const ele = document.querySelector("#rong-cloud-tv [signal=rc-video]");
      let agoW = ele.offsetWidth;
      let agoH = ele.offsetHeight;
      let num = agoW * 720 - agoH * 1280;
      return num;
    },
    // 追踪标记
    handleMouseDown($event) {
      this.drawStyle.flag = true;
      this.drawStyle.width = 0;
      this.drawStyle.height = 0;
      this.drawStyle.borderWidth = 0;
      this.drawStyle.startX = $event.clientX;
      this.drawStyle.startY = $event.clientY;
      this.drawStyle.marginLeft = $event.offsetX;
      this.drawStyle.marginTop = $event.offsetY;
    },
    handleMouseMove($event) {
      if (this.drawStyle.flag) {
        this.drawStyle.endX = $event.clientX;
        this.drawStyle.endY = $event.clientY;
        this.drawStyle.borderWidth = 2;

        let w = this.drawStyle.endX - this.drawStyle.startX;
        let h = this.drawStyle.endY - this.drawStyle.startY;
        if (w > 0 && h > 0) {
          this.drawStyle.width = w;
          this.drawStyle.height = h;
        }
      }
    },
    async handleMouseUp() {
      const { channelId } = this.getRongCloud;

      let ratio = 1280 / this.trackStyle.width;
      this.drawStyle.flag = false;
      let options = {
        x: this.drawStyle.marginLeft,
        y: this.drawStyle.marginTop,
        w: this.drawStyle.width,
        h: this.drawStyle.height,
      };
      if (options.w < 20 || options.h < 20) return;
      this.rtcOperation.operaContent.operaName = "follow";
      // 点坐标
      let points = {
        x1: (options.x * ratio) / 1280,
        y1: (options.y * ratio) / 720,
        x2: ((options.x + options.w) * ratio) / 1280,
        y2: ((options.y + options.h) * ratio) / 720,
      };
      this.rtcOperation.operaContent.followObj = points;
      // 取异步地址发送给安卓
      // 保存截图
      this.rtcOperation.operaContent.followObj.trackShotUrl =
        await this.saveShot();
      // 多屏切单屏从doubleClickUser取；一直是单屏，取getCallDialogData.callId
      const currentUser =
        this.doubleClickUser || this.getCallDialogData.callerId;
      this.sendMessage(this.rtcOperation, currentUser, false);
    },
    // 保存截图
    saveShot() {
      return new Promise((resolve, reject) => {
        const base64 = this.currentFrameToImg();

        //  this.rtcOperation.operaContent.followObj.trackShotUrl
        this.$axios({
          url: "api/csp/cos/v1/url/uploadBase64",
          method: "post",
          data: {
            data: {
              base64,
              suffix: "jpeg",
            },
          },
        }).then(({ data }) => {
          if (!data.status) {
            resolve(data.data);
          } else {
            reject();
          }
        });
      });
    },
    // 清除标记
    handleClearMark() {
      this.operaList.mark = false;
      this.operaOptions.operaName = "clear-mark";
      this.operaOptions.value = 0;
      this.handleOperation(this.operaOptions);
    },
    // 参会人
    handleSetNum() {
      if (this.silderList.showIm) {
        this.silderList = {
          title: "通讯列表",
          showIm: false,
        };
        this.operaList.setNum = true;
        this.sessionPersonPopver = true;
      } else {
        this.silderList = {
          title: "消息",
          showIm: true,
        };
        this.operaList.setNum = false;
        this.sessionPersonPopver = false;
      }
    },

    // 根据channel获取参会人
    getUserByChannel() {
      if (!this.rtcChannel) {
        return;
      }
      this.$axios({
        method: "get",
        url: `api/csp/rc/v1/session/attendeeUsers/${this.rtcChannel}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          this.sessionPerson = data.data;
          this.sessionNum = this.sessionPerson.length;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 查询聊天室信息
    searchChatRoom() {
      var chatRoom = im.ChatRoom.get({
        id: "chatRoom1",
      });
      chatRoom.getInfo().then(function (result) {
        var userCount = result.userCount;
        var userInfos = result.userInfos;
        console.log("获取聊天室信息成功", userCount, userInfos);
      });
    },
    // 邀请
    async handleInvite(item) {
      const targetId = item.userId.toString();
      const { realName, fullAvatar, id } = this.getUserInfo;

      // 显示邀请弹窗
      this.$store.commit("setCallDialogData", {
        show: true,
        avatar: "",
        name: item.realName,
        isCalled: false,
      });

      let message = {
        rtc_call: {
          channel: this.getRongCloud.channelId,
          token: "",
          platform: "join",
          name: realName,
          avatar: fullAvatar,
          userId: id,
        },
      };

      this.sendMessage(message, targetId, false);
    },
    // 地图
    handleMap() {
      if (this.operaList.map) {
        this.operaList.map = false;
        this.showMap = false;
        this.initMap = false;
      } else {
        this.getPolylinesByChannel();
      }
    },
    // 根据当前channel获取参会人坐标
    getPolylinesByChannel() {
      if (!this.getRongCloud.channelId) {
        return;
      }
      this.showMap = true;
      this.operaList.map = true;
      this.$axios({
        method: "get",
        url: `api/csp/mgr/v1/userGps/recordByChannel/${this.getRongCloud.channelId}`,
      }).then((response) => {
        let data = response.data;
        if (!data.status) {
          let list = data.data.filter((item) => {
            return item.gpsList.length > 0;
          });
          if (list.length) {
            this.polylinesArr = list;
          }
          this.initMap = true;
        } else {
          this.$message({
            type: "error",
            message: data.msg,
            duration: 1000,
          });
        }
      });
    },
    // 发送文本消息
    async handleKeyUpEnter() {
      const { channelId } = this.getRongCloud;
      this.currentMsg.chatContent.oneself = 1;
      this.currentMsg.chatContent.type = 31;
      this.currentMsg.chatContent.content = this.currentChat;

      this.saveChatMessage(this.currentMsg);
      this.sendMessage(this.currentMsg, channelId, true);
    },
    saveChatMessage(newMsg) {
      // 取出历史信息
      const chatHistory = this.getChatRoomMessages;
      // 设置信息
      this.$store.commit("setChatRoomMessages", [
        ...chatHistory,
        newMsg.chatContent,
      ]);
      this.contentToBottom();
    },
    contentToBottom: () => {
      setTimeout(() => {
        document.querySelector(".chat-info").scrollTop = 99999999999;
      }, 0);
    },
    // 获取上传文件地址
    getUploadDir(type) {
      return new Promise((resolve, reject) => {
        this.$axios({
          method: "get",
          url: "api/csp/cos/v1/url/upload",
          params: {
            dirType: "upload",
            suffix: type,
          },
        })
          .then((response) => {
            let data = response.data;
            if (!data.status) {
              this.action = data.data;
            } else {
              this.$message({
                type: "error",
                message: data.msg,
                duration: 1000,
              });
            }
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },
    async handleHttpRequest(file) {
      this.fileType = file.raw.type.split("/")[1];
      let type = "";
      if (this.fileType.indexOf("zip") > -1) {
        type = "zip";
      } else if (this.fileType.indexOf("png") > -1) {
        type = "png";
      } else if (this.fileType.indexOf("jpeg") > -1) {
        type = "jpeg";
      } else {
        type = "xlsx";
      }
      await this.getUploadDir(type);
      let xhr = new XMLHttpRequest();
      xhr.open("PUT", this.action.url, true);
      xhr.onload = (e) => {
        let content = this.action.url.split("?")[0];
        this.sendFileMsg(type, content);
      };
      xhr.onerror = (e) => {
        console.log("上传出错", xhr.status, xhr.statusText);
      };
      xhr.send(file.raw);
    },
    // 发送文件消息
    sendFileMsg(fileType, content) {
      const { channelId } = this.getRongCloud;

      let type = 32;
      if (fileType == "xlsx" || fileType == "zip") {
        type = 33;
      }
      this.currentMsg.chatContent.oneself = 1;
      this.currentMsg.chatContent.type = type;
      this.currentMsg.chatContent.content = content;

      this.saveChatMessage(this.currentMsg);
      this.contentToBottom();
      this.sendMessage(this.currentMsg, channelId, true);
    },
    // 发送截图给客户端
    async sendShotImg(url) {
      let file = this.$Utils.dataURLToBlob(url);
      await this.getUploadDir("png");
      let xhr = new XMLHttpRequest();
      xhr.open("PUT", this.action.url, true);
      xhr.onload = (e) => {
        let content = this.action.url.split("?")[0];
        this.sendFileMsg(32, content);
      };
      xhr.onerror = (e) => {
        console.log("上传出错", xhr.status, xhr.statusText);
      };
      xhr.send(file);
    },
    // // 发送白板给客户端
    // async sendWhiteBoard(options, url) {
    //   let file = this.$Utils.dataURLToBlob(url);
    //   await this.getUploadDir("png");
    //   let xhr = new XMLHttpRequest();
    //   xhr.open("PUT", this.action.url, true);
    //   xhr.onload = (e) => {
    //     let content = this.action.url.split("?")[0];

    //     this.currentMsg.chatContent.oneself = 1;
    //     this.currentMsg.chatContent.type = 21;
    //     this.currentMsg.chatContent.content = content;
    //     this.currentMsg.chatContent.appId = options.appId;
    //     this.currentMsg.chatContent.channel = options.channel;
    //     this.currentMsg.chatContent.roomToken = options.roomToken;
    //     this.sendMsg(this.currentMsg);
    //   };
    //   xhr.onerror = (e) => {
    //     console.log("上传出错", xhr.status, xhr.statusText);
    //   };
    //   xhr.send(file);
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/currentCollaboration.scss";
</style>
