<template>
  <div>123</div>
</template>

<script>
export default {
  name: 'empty',
  data() {
    return {
    };
  },
  created() {
    this.$router.push({
      path: '/remoteCollaboration/currentCollaboration',
      query: {
        type: this.$route.query.type
      }
    })
  },
};
</script>