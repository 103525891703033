var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "js-dialog-form js-dialog-call",
      attrs: {
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "show-close": false,
        width: "360px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("ul", { staticClass: "exit" }, [
        _c("li", [
          _c(
            "i",
            {
              staticClass: "icon",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleLeave.apply(null, arguments)
                },
              },
            },
            [_vm._v("离开")]
          ),
        ]),
        _c("li", [
          _c(
            "i",
            {
              staticClass: "icon red",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.handleOver.apply(null, arguments)
                },
              },
            },
            [_vm._v("结束")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }