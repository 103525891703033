var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rong-cloud-initial" },
    [
      _vm._v(" rong-cloud-test-page "),
      _c(
        "el-button",
        { attrs: { type: "primary" }, on: { click: _vm.sendCall } },
        [_vm._v("拨通test的电话")]
      ),
      _c("el-button", { on: { click: _vm.accept } }, [_vm._v("接听")]),
      _c("el-button", { on: { click: _vm.hangup } }, [_vm._v("挂断")]),
      _c("div", { attrs: { id: "rong-cloud-tv" } }),
      _c("call-dialog", {
        attrs: {
          isJoin: _vm.callDialog.isJoin,
          callName: _vm.callDialog.callName,
        },
        on: {
          handleAnswer: _vm.handleAnswer,
          handleRefuse: _vm.handleRefuse,
          handleCancel: _vm.handleCancel,
        },
        model: {
          value: _vm.callDialog.visible,
          callback: function ($$v) {
            _vm.$set(_vm.callDialog, "visible", $$v)
          },
          expression: "callDialog.visible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }