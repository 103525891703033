<template>
  <div class="rong-cloud-initial">
    rong-cloud-test-page
    <el-button type="primary" @click="sendCall">拨通test的电话</el-button>
    <el-button @click="accept">接听</el-button>
    <el-button @click="hangup">挂断</el-button>
    <div id="rong-cloud-tv"></div>
    <call-dialog
      v-model="callDialog.visible"
      :isJoin="callDialog.isJoin"
      :callName="callDialog.callName"
      @handleAnswer="handleAnswer"
      @handleRefuse="handleRefuse"
      @handleCancel="handleCancel"
    >
    </call-dialog>
  </div>
</template>

<script>
import CallDialog from "@/components/Call";
//导入 IMLib 5.X
import * as RongIMLib from "@rongcloud/imlib-next";
// 导入 RTCLib、CallLib
import {
  installer as rtcInstaller,
  RCRTCClient,
  RCTrack,
  RCFrameRate,
  RCResolution,
} from "@rongcloud/plugin-rtc";

import {
  installer as callInstaller,
  RCCallClient,
  RCCallSession,
  RCCallErrorCode,
  IDeviceChangeParams,
  ISessionListener,
  IEndSummary,
  ISenderInfo,
  IMuteUser,
  IInvitedUsers,
  RCCallLanguage,
  RCCallEndReason,
  RCCallMediaType,
  IOfflineRecord,
  RCCallSessionState,
} from "@rongcloud/plugin-call";
const appkey = "25wehl3u2v0uw";

const testUser = {
  token:
    "Zy4SJIXnGp3pwnj46Lx/vocymGqGi+jQO4n2pelwFHE=@o543.cn.rongnav.com;o543.cn.rongcfg.com",
  id: "test",
};
const myself = {
  token:
    "1dz5iWvuRxSKAxVAD3nlz58XV/5iyQnq@o543.cn.rongnav.com;o543.cn.rongcfg.com",
  id: "xc",
};

export default {
  components: {
    CallDialog,
  },
  data() {
    return {
      // Rong-cloud-constant
      rtcClient: null,
      caller: null,
      callSession: null,
      mediaType: 2,
      // call-dialog-params
      callDialog: {
        visible: false,
        callName: "",
        rtmCommonChannel: null,
        isJoin: false,
        rtc_options: {
          channel: "",
          token: "",
        },
      },
    };
  },
  mounted() {
    this.initial();
  },
  methods: {
    initial() {
      // IM 客户端初始化（IMLib 5.X）
      RongIMLib.init({
        appkey,
      });
      const _this = this;
      // RTC 客户端初始化
      this.rtcClient = RongIMLib.installPlugin(rtcInstaller);
      // const rtcClient: RCRTCClient = RongIMLib.installPlugin(rtcInstaller)

      // CallLib 客户端初始化
      this.caller = RongIMLib.installPlugin(callInstaller, {
        rtcClient: this.rtcClient,
        /**
         * 被动收到邀请 （收到一个远端发起的新会话）, 会产生一个新的 session 对象 （必填）
         */
        onSession(session) {
          /**
           * **收到新的 session 后需要立即注册事件监听**
           */
          _this.callSession = session;
          _this.callDialog.visible = true;
          _this.callDialog.isJoin = true;
          session.registerSessionListener({
            /**
             * 当远端用户已开始响铃，表示对方已收到呼叫请求
             * @param sender 已响铃的用户
             * @param session 当前的 session 对象
             */
            onRinging(sender, session) {
              const { userId } = sender;
            },

            /**
             * 当远端用户同意接听
             * @param sender 远端用户
             * @param session 当前的 session 对象
             */
            onAccept(sender, session) {
              const { userId } = sender;
            },

            /**
             * 当有远端用户挂断
             * @param sender 远端用户
             * @param reason 挂断的原因
             * @param session 当前的 session 对象
             */
            onHungup(sender, reason, session) {
              const { userId } = sender;
            },

            /**
             * 本端资源或远端资源已获取
             * @param track 本端资源或远端资源, track 不可设置成 Vue 组件的响应式数据
             * @param session 当前的 session 对象
             */
            onTrackReady(track, session) {
              // track.isLocalTrack() 是否为本地资源
              // track.isAudioTrack() 是否为音频
              // track.isVideoTrack() 是否为视频
              // track.getUserId()    产生该 track 的用户id

              // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
              if (track.isAudioTrack() && !track.isLocalTrack()) {
                track.play();
              }

              // 视频在对应的容器里播放
              if (track.isVideoTrack()) {
                const container = document.getElementById("rong-cloud-tv");
                const uid = track.getUserId();
                const node = document.createElement("div");
                node.setAttribute("id", `video-${uid}`);
                const videoTpl = `<span class="video-user-id">ID: ${uid}</span>
      <span class="video-media-type">${
        _this.mediaType === 1 ? "音频" : ""
      }</span>
      <video id="${uid}"></video>`;
                node.innerHTML = videoTpl;
                node.classList = "video-item";
                container.appendChild(node);
                const videoEl = document.getElementById(track.getUserId());

                track.play(videoEl);
              }
            },
          });
        },

        /**
         *  以下三条只要满足一条，就会触发onSessionClose
         *  1、本端用户自己主动挂断
         *  2、服务端把本端用户踢出 RTC 房间
         *  3、房间里小于2个人
         *
         *  @param {RCCallSession} session 被结束的 session 对象
         *  @param summaryInfo 结束一个 session 的后汇总信息
         */
        onSessionClose(session, summaryInfo) {},
      });

      // 建立IM链接后才可以进行呼叫；
      this.connect(myself.token);
    },
    connect(userToken) {
      RongIMLib.connect(userToken).then((res) => {
        if (res.code === 0) {
          this.$message.success("链接成功, 链接用户 id 为: " + res.data.userId);
        } else {
          this.$message.error("链接失败, code:" + res.code);
        }
      });
    },
    async sendCall() {
      /**
       * 发起单人通话，如果成功后会产生一个新的session
       * @param targetId 被呼叫一方的用户 id   必填
       * @param mediaType 1->音频呼叫 or 2->音视频呼叫  必填
       * @param listener session对象上注册的事件 （必填）
       * @param constraints 获取音频或音视频资源时的参数 可选
       * @param params.channelId 组织 Id 可选
       */
      const _this = this;
      const { code, session } = await this.caller.call({
        targetId: testUser.id,
        mediaType: 2,
        listener: {
          /**
           * 当远端用户已开始响铃，表示对方已收到呼叫请求 （必填）
           * @param sender 已响铃的用户
           * @param session 当前的 session 对象
           */
          onRinging(sender, session) {
            const { userId } = sender;
          },

          /**
           * 当远端用户同意接听 （必填）
           * @param sender 远端用户
           * @param session 当前的 session 对象
           */
          onAccept(sender, session) {
            const { userId } = sender;
          },

          /**
           * 当有远端用户挂断 （必填）
           * @param sender 远端用户
           * @param reason 挂断的原因
           * @param session 当前的 session 对象
           */
          onHungup(sender, reason, session) {
            const { userId } = sender;
          },

          /**
           * 本端资源或远端资源已获取 （必填）
           * @param track 本端资源或远端资源, track 不可设置成 Vue 组件的响应式数据
           * @param session 当前的 session 对象
           */
          onTrackReady(track, session) {
            // track.isLocalTrack() 是否为本地资源
            // track.isAudioTrack() 是否为音频
            // track.isVideoTrack() 是否为视频
            // track.getUserId()    产生该 track 的用户id

            // 播放音频。如果为远端音频，建议直接播放。如为本端音频，建议不播放，以减少回音。
            if (track.isAudioTrack() && !track.isLocalTrack()) {
              track.play();
            }

            // 视频在对应的容器里播放
            if (track.isVideoTrack()) {
              const container = document.getElementById("rong-cloud-tv");
              const uid = track.getUserId();
              const node = document.createElement("div");
              node.setAttribute("id", `video-${uid}`);
              const videoTpl = `<span class="video-user-id">ID: ${uid}</span>
      <span class="video-media-type">${
        _this.mediaType === 1 ? "音频" : ""
      }</span>
      <video id="${uid}"></video>`;
              node.innerHTML = videoTpl;
              node.classList = "video-item";
              container.appendChild(node);
              const videoEl = document.getElementById(track.getUserId());

              track.play(videoEl);
            }
          },
        },
      });

      if (code === RCCallErrorCode.SUCCESS) {
        // do something
      }
    },
    hangup() {
      this.callSession
        .hungup()
        .then(({ code }) => {
          if (code === RCCallErrorCode.SUCCESS) {
            this.$message.success("挂断成功");
          } else {
            this.$message.error(`挂断失败，错误原因：${code}`);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(`挂断失败，错误原因：${err}`);
        });
    },
    accept() {
      this.callSession.accept().then(({ code }) => {
        if (code === RCCallErrorCode.SUCCESS) {
          this.$message.success("接听成功");
        } else {
          this.$message.error(`接听失败，错误原因：${code}`);
        }
      });
    },
    cleanContainer() {
      document.getElementById("rong-cloud-tv").innerHTML = "";
    },
    handleAnswer() {
      this.callDialog.visible = false;
      this.isJoin = false;
      this.accept();
    },
    // 拒绝呼叫
    handleRefuse() {
      // debugger;
      this.callDialog.visible = false;
      this.hangup();
    },
    // 取消呼叫
    handleCancel() {
      this.callDialog.visible = false;
      this.hangup();
    },
  },
};
</script>

<style scoped>
.rong-cloud-initial {
  margin: 0 auto;
}
#rong-cloud-tv {
  width: 300px;
  height: 200px;
  border: 1px solid #000;
}
</style>
