var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-cur-wrap" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.getIsSplitScreen,
            expression: "getIsSplitScreen",
          },
        ],
        staticClass: "js-common-wrap js-cur-collaboration",
      },
      [
        _c(
          "div",
          { staticClass: "media-box", class: _vm.mediaClass },
          _vm._l(_vm.mediaCount, function (i) {
            return _c("div", { key: i, staticClass: "media-box-item" }, [
              _c(
                "div",
                {
                  staticClass: "split-item-opera",
                  on: {
                    dblclick: function ($event) {
                      return _vm.switchCurrentScreen(i - 1)
                    },
                  },
                },
                [
                  _c("ul", { staticClass: "btns" }, [
                    _c("li", { staticClass: "btns-item" }, [
                      _c("i", {
                        staticClass: "exit",
                        on: {
                          click: function ($event) {
                            return _vm.kickOutUser(i - 1)
                          },
                        },
                      }),
                      _vm._v("踢出 "),
                    ]),
                  ]),
                ]
              ),
              _c("div", { staticClass: "tip" }, [_vm._v("暂无会话")]),
            ])
          }),
          0
        ),
        _c("div", { staticClass: "media-opera" }, [
          _c("ul", [
            _c(
              "li",
              [
                _c(
                  "el-button",
                  {
                    attrs: { round: "", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.switchScreen(1)
                      },
                    },
                  },
                  [_vm._v("返回单屏")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-button",
                  {
                    class: _vm.mediaCount === 4 ? "" : "is-plain",
                    attrs: { round: "", type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.switchScreen(4)
                      },
                    },
                  },
                  [_vm._v("4分屏")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-button",
                  {
                    class: _vm.mediaCount === 9 ? "" : "is-plain",
                    attrs: { round: "", type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.switchScreen(9)
                      },
                    },
                  },
                  [_vm._v("9分屏")]
                ),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-button",
                  {
                    class: _vm.mediaCount === 16 ? "" : "is-plain",
                    attrs: { round: "", type: "primary", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.switchScreen(16)
                      },
                    },
                  },
                  [_vm._v("16分屏")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.getIsSplitScreen,
            expression: "!getIsSplitScreen",
          },
        ],
        staticClass: "js-common-wrap js-cur-collaboration",
      },
      [
        _c("div", { staticClass: "video" }, [
          _c("div", { staticClass: "agoraRTC" }, [
            _c(
              "div",
              { ref: "agorartc", attrs: { id: "rong-cloud-tv" } },
              [
                _c("div", { staticClass: "video-wrapper" }),
                _vm.noSignal
                  ? _c("el-empty", {
                      staticStyle: { height: "100%" },
                      attrs: { description: "暂无会话" },
                    })
                  : _vm._e(),
                _vm.currentUrl
                  ? _c("div", { staticClass: "shot-box" }, [
                      _c("img", {
                        staticClass: "img",
                        attrs: { src: _vm.currentUrl, alt: "" },
                      }),
                      _c(
                        "div",
                        { staticClass: "btns" },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { size: "medium" },
                              on: { click: _vm.cancelShot },
                            },
                            [_vm._v("取 消")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "primary", size: "medium" },
                              on: { click: _vm.confirmShot },
                            },
                            [_vm._v("确 定")]
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.trackFlag
                  ? _c(
                      "div",
                      {
                        staticClass: "track-box",
                        style: {
                          width: _vm.trackStyle.width + "px",
                          height: _vm.trackStyle.height + "px",
                        },
                        on: {
                          mousedown: _vm.handleMouseDown,
                          mousemove: _vm.handleMouseMove,
                          mouseup: _vm.handleMouseUp,
                        },
                      },
                      [
                        _vm.drawStyle.flag
                          ? _c("div", {
                              staticClass: "draw",
                              style: {
                                width: _vm.drawStyle.width + "px",
                                height: _vm.drawStyle.height + "px",
                                marginLeft: _vm.drawStyle.marginLeft + "px",
                                marginTop: _vm.drawStyle.marginTop + "px",
                                borderWidth: _vm.drawStyle.borderWidth + "px",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "close-track",
                            attrs: {
                              size: "small",
                              type: "success",
                              round: "",
                            },
                            on: { click: _vm.exitTrack },
                          },
                          [_vm._v("关闭追踪")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm.showMap
              ? _c(
                  "div",
                  { staticClass: "map-box" },
                  [
                    _c("baidu-map", {
                      attrs: {
                        initMap: _vm.initMap,
                        scrollWheelZoom: true,
                        showCall: false,
                        polylinesArr: _vm.polylinesArr,
                        center: _vm.center,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("ul", { staticClass: "agoraRTC-opera" }, [
            _c("li", [
              _c("i", {
                staticClass: "icon icon-mode",
                class: _vm.operaList.mode ? "active" : "",
                on: { click: _vm.splitScreen },
              }),
              _c("p", [_vm._v("分屏模式")]),
            ]),
            _c("li", [
              _c("i", {
                staticClass: "icon icon-change-focal",
                class: _vm.operaList.changeFocal.value == 1 ? "" : "active",
                on: { click: _vm.handleChaneFocal },
              }),
              _c("p", [_vm._v(_vm._s(_vm.operaList.changeFocal.text))]),
            ]),
            _c(
              "li",
              [
                _c(
                  "el-popover",
                  {
                    attrs: { placement: "top-start", trigger: "manual" },
                    model: {
                      value: _vm.focalPopver,
                      callback: function ($$v) {
                        _vm.focalPopver = $$v
                      },
                      expression: "focalPopver",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "agoraRTC-focal-list" },
                      _vm._l(_vm.focalList, function (i) {
                        return _c(
                          "p",
                          {
                            key: i.value,
                            staticClass: "item",
                            class: i.value == _vm.focalValue ? "active" : "",
                            on: {
                              click: function ($event) {
                                return _vm.handleFocal(i.value)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(i.text) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("i", {
                      staticClass: "icon icon-focal",
                      class: _vm.operaList.focal ? "active" : "",
                      attrs: { slot: "reference" },
                      on: {
                        click: function ($event) {
                          _vm.focalPopver = !_vm.focalPopver
                        },
                      },
                      slot: "reference",
                    }),
                  ]
                ),
                _c("p", [_vm._v("对焦")]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "200",
                      trigger: "manual",
                    },
                    model: {
                      value: _vm.markPopver,
                      callback: function ($$v) {
                        _vm.markPopver = $$v
                      },
                      expression: "markPopver",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "agoraRTC-mark-list" },
                      _vm._l(_vm.markList, function (item, index) {
                        return _c(
                          "p",
                          {
                            key: index,
                            staticClass: "item",
                            class: [
                              item.class,
                              _vm.currentMark == item.value ? "active" : "",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.markItemClick(item)
                              },
                            },
                          },
                          [_c("i"), _vm._v(_vm._s(item.text) + " ")]
                        )
                      }),
                      0
                    ),
                    _c("i", {
                      staticClass: "icon icon-mark",
                      class: _vm.operaList.mark ? "active" : "",
                      attrs: { slot: "reference" },
                      on: { click: _vm.handleMark },
                      slot: "reference",
                    }),
                  ]
                ),
                _c("p", [_vm._v("标记")]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "200",
                      trigger: "manual",
                    },
                    model: {
                      value: _vm.sessionPersonPopver,
                      callback: function ($$v) {
                        _vm.sessionPersonPopver = $$v
                      },
                      expression: "sessionPersonPopver",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "agoraRTC-user-list" },
                      [
                        _vm._l(_vm.sessionPerson, function (person, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "item" },
                            [
                              _c("i"),
                              _c("div", { staticClass: "info" }, [
                                _c("p", [_vm._v(_vm._s(person.name))]),
                                _c("p", [_vm._v(_vm._s(person.realName))]),
                              ]),
                            ]
                          )
                        }),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              size: "mini",
                              plain: "",
                              round: "",
                            },
                            on: { click: _vm.joinSession },
                          },
                          [
                            _vm._v("邀请"),
                            _c("i", { staticClass: "el-icon-plus" }),
                          ]
                        ),
                      ],
                      2
                    ),
                    _c(
                      "i",
                      {
                        staticClass: "icon icon-num",
                        class: _vm.operaList.setNum ? "active" : "",
                        attrs: { slot: "reference" },
                        on: { click: _vm.handleSetNum },
                        slot: "reference",
                      },
                      [
                        _c("span", { staticClass: "num" }, [
                          _vm._v(_vm._s(_vm.sessionNum)),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c("p", [_vm._v("参会人")]),
              ],
              1
            ),
            _c("li", [
              _c("i", {
                staticClass: "icon icon-map",
                on: { click: _vm.handleMap },
              }),
              _c("p", [_vm._v("地图")]),
            ]),
            _c("li", [
              _c("i", {
                staticClass: "icon icon-screen",
                class: _vm.operaList.allScreen ? "active" : "",
                on: { click: _vm.handleAllScreen },
              }),
              _c("p", [_vm._v("全屏")]),
            ]),
            _c("li", [
              _c("i", {
                staticClass: "icon icon-share-screen",
                class: _vm.operaList.shareScreen ? "active" : "",
                on: { click: _vm.handleShareScreen },
              }),
              _c("p", [_vm._v("共享屏幕")]),
            ]),
            _c(
              "li",
              [
                _c(
                  "el-popover",
                  {
                    attrs: { placement: "top", trigger: "hover", content: "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "popup" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { plain: "" },
                            on: { click: _vm.leaveRoom },
                          },
                          [_vm._v("离开")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "danger", plain: "" },
                            on: { click: _vm.endConversation },
                          },
                          [_vm._v("结束对话")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "wrapper",
                        attrs: { slot: "reference" },
                        slot: "reference",
                      },
                      [
                        _c("i", { staticClass: "icon icon-exit" }),
                        _c("p", [_vm._v("退出会话")]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c("div", { staticClass: "silder-list" }, [
          _c("div", { staticClass: "silder-list-title" }, [
            _vm._v(_vm._s(_vm.silderList.title)),
          ]),
          _c("div", { staticClass: "silder-list-content" }, [
            _vm.silderList.showIm
              ? _c("div", { staticClass: "chat" }, [
                  _c("div", { staticClass: "chat-info" }, [
                    _c(
                      "div",
                      {
                        staticClass: "chat-info-details",
                        class:
                          _vm.getChatRoomMessages &&
                          _vm.getChatRoomMessages.length
                            ? ""
                            : "no-data",
                      },
                      [
                        _vm.getChatRoomMessages &&
                        _vm.getChatRoomMessages.length
                          ? _c(
                              "ul",
                              _vm._l(
                                _vm.getChatRoomMessages,
                                function (info, index) {
                                  return _c(
                                    "li",
                                    { key: index },
                                    [
                                      info.type > 30 && info.type < 40
                                        ? [
                                            _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "name",
                                                  class: info.oneself
                                                    ? "self"
                                                    : "",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        info.userRealName
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              info.type == 31
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "info",
                                                      class: info.oneself
                                                        ? "self"
                                                        : "",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "text" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(info.content)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              info.type == 32
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "info",
                                                      class: info.oneself
                                                        ? "self"
                                                        : "",
                                                    },
                                                    [
                                                      _c("el-image", {
                                                        staticClass: "img",
                                                        attrs: {
                                                          src: info.content,
                                                          "preview-src-list": [
                                                            info.content,
                                                          ],
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              info.type == 33
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass: "info",
                                                      class: info.oneself
                                                        ? "self"
                                                        : "",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "href" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href: info.content,
                                                                target:
                                                                  "_blank",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "点击下载文件"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ]
                                        : [
                                            _c("div", [
                                              info.type == 21
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sysInfo" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            info.userRealName
                                                          ) +
                                                          "创建白板 "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              info.type == 22
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sysInfo" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            info.userRealName
                                                          ) +
                                                          "加入白板 "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              info.type == 23
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sysInfo" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            info.userRealName
                                                          ) +
                                                          "退出白板 "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]),
                                          ],
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            )
                          : _c("el-empty", {
                              attrs: { description: "暂无数据" },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "chat-input" },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload",
                          attrs: {
                            action: "#",
                            accept: ".png,.jpg",
                            "show-file-list": false,
                            "auto-upload": false,
                            "on-change": _vm.handleHttpRequest,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-folder-opened" })]
                      ),
                      _c("el-input", {
                        staticClass: "input",
                        attrs: {
                          size: "small",
                          placeholder: "请输入……",
                          clearable: "",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.handleKeyUpEnter.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.currentChat,
                          callback: function ($$v) {
                            _vm.currentChat = $$v
                          },
                          expression: "currentChat",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _c(
                  "ul",
                  { staticClass: "session-person-content js-call-list" },
                  _vm._l(_vm.userList, function (item) {
                    return _c(
                      "li",
                      { key: item.userId, staticClass: "js-call-list-item" },
                      [
                        _c("img", {
                          staticClass: "client-icon",
                          attrs: {
                            src: require("@/assets/images/userImg.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "client-info" }, [
                          _c("p", { staticClass: "client-name" }, [
                            _vm._v(_vm._s(item.realName)),
                          ]),
                          _c("i", {
                            staticClass: "client-type",
                            class:
                              item.devType === "web" ? "client-type-web" : "",
                          }),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "client-call",
                            attrs: { type: "primary", size: "mini", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.handleInvite(item)
                              },
                            },
                          },
                          [_vm._v("邀请")]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }